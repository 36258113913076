import { Component, OnInit } from '@angular/core';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { ModalController,LoadingController, NavController } from '@ionic/angular';
import { UtilService } from '../../services/util.service';
import { ActivatedRoute } from '@angular/router';
import { ApiabertaService } from '../../services/apiaberta.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

@Component({
  selector: 'app-upload-file-camara',
  templateUrl: './upload-file-camara.component.html',
  styleUrls: ['./upload-file-camara.component.scss'],
})
export class UploadFileCamaraComponent{
  mensagem:string='';
  Tabela:string='';
  Idtabela: number = 0;
  FotoAtualUsuario:any;
  imagemEnviada:string;
  imageURI:string='';
  IdUsuario:number=0;
  Coluna:string="";
  Retorno:string="";
  Imagem:string='';
  ImagemServidor:string='';

  constructor(
    private navController: NavController,
    private utilService: UtilService,
    private transfer: FileTransfer,
    private loadingController: LoadingController,
    private route: ActivatedRoute,
    private apiabertaService:ApiabertaService,
    private camera:Camera,
    private modalController:ModalController,
    private activatedRoute:ActivatedRoute
  ) { 
    this.Idtabela = parseInt(this.activatedRoute.snapshot.paramMap.get('Idtabela'));
    this.Imagem = this.activatedRoute.snapshot.paramMap.get("Imagem");
    this.Tabela = this.activatedRoute.snapshot.paramMap.get('Tabela');
    this.Coluna = this.activatedRoute.snapshot.paramMap.get('Coluna');

  }

  ngOnInit() {
  }

  ionViewDidEnter() {

   
   
  }

  closeModal() {
    this.modalController.dismiss();
  }

  

  async enviarArquivo() {
    this.mensagem = 'imagem sendo enviada....';
    let loading = await this.loadingController.create({
      message: this.mensagem,
    });
    loading.present();
    let randonNumero = Math.floor(Math.random() * 1000000);
    let imagem = "U" + this.Idtabela.toString() + randonNumero + ".png";
    const fileTransfer: FileTransferObject = this.transfer.create();

    let options: FileUploadOptions = {
      fileKey: 'ionicfile',
      fileName: imagem,
      chunkedMode: false,
      mimeType: "image/png",
      headers: {}
    }
    let urlUpload = this.utilService.obterUrlDaApi() + "Util/EnviarArquivo";
    fileTransfer.upload(this.imageURI, urlUpload, options)
      .then((data) => {
        //debugger;
        this.utilService.showAlertCallback('imagem enviada com sucesso');
        this.imagemEnviada = this.utilService.obterUrlDaImagemDaApi() + imagem;

       // this.utilService.publishEvent(this.Tabela, this.imagemEnviada);

        // caso imagem enviada com sucesso
       
        loading.dismiss();
        this.gravarSqlServer(imagem)

      }, (err) => {
        console.error(err);
        this.utilService.showAlertCallback("Imagem NÃO pode ser enviada!");
      }).finally(function () {
        loading.dismiss();
      });
  }

  async gravarSqlServer(imagem:any) {

    let jImagem = {
      Idtabela: this.Idtabela,
      Usuario: this.IdUsuario,
      Tabela: this.Tabela,
      Nome: imagem,
      Descricao: 'Descricao',
      Texto: 'Texto',
      Coluna: this.Coluna

    };

    this.apiabertaService.atualizarImagemGeral(jImagem)
    .then((response) => {
     this.utilService.showAlertCallback("Arquivo enviado com sucesso!", null);

    })
    .catch((erro) => {
      this.utilService.showAlertCallback(JSON.stringify(erro));
    });
  }

  getImage() {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
     // sourceType: this.camera.PictureSourceType.SAVEDPHOTOALBUM
    }
    
    this.camera.getPicture(options).then((imageData) => {
      this.imageURI = imageData;
      setTimeout(()=>{
        this.enviarArquivo();
      },1000);
    }, (err) => {
      this.utilService.showAlertCallback("Imagem NÃO foi selecionada!");
    });
  }

  tirarFoto() {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true,
      cameraDirection: this.camera.Direction.FRONT,
      targetWidth: 960,
      targetHeight: 540,
    }

    this.camera.getPicture(options).then((imageData) => {
      this.imageURI = imageData;
      //debugger
      this.enviarArquivo();
    }, (err) => {
      this.utilService.showAlertCallback("Imagem NÃO foi selecionada!");
    });
  }

  

 
  

}
