import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { UtilService } from '../../services/util.service';
import { Platform, ModalController, LoadingController, AlertController, IonThumbnail } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { ApiabertaService } from '../../services/apiaberta.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  uploadForm: FormGroup;
  files: File[] = [];
  Imagem: string = '';
  Idtabela: number = 0;
  Tabela: string = "";
  Coluna: string = "";
  ImagemServidor: string = '';
  imageURI:any;
  isCordova:boolean=false
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private loadingController: LoadingController,
    private camera:Camera,
    private transfer: FileTransfer,
    private platform:Platform,
    private modalController:ModalController,
    private apiabertaService:ApiabertaService

  ) {

    this.Imagem = this.activatedRoute.snapshot.paramMap.get("Imagem");
    this.Tabela = this.activatedRoute.snapshot.paramMap.get('Tabela');
    this.Coluna = this.activatedRoute.snapshot.paramMap.get('Coluna');
    this.Idtabela = parseInt(this.activatedRoute.snapshot.paramMap.get('Idtabela'));

    this.uploadForm = this.fb.group({
      files: [null, Validators.required]
    });

  }

  ngOnInit() {

    // PARA MOSTRAR A IMAGEM ANTERIOR DO SERVIDOR
    if (this.Imagem != '' && this.Imagem != null && this.Imagem != undefined) {
      this.ImagemServidor = this.utilService.obterUrlDaImagemDaApi() + this.Imagem
    }

    if (this.platform.is('cordova') || this.platform.is('ios')) {
      this.isCordova = true;
    }

    this.ImagemServidor =  this.Imagem;

  }

  

  closeModal() {
   
    this.utilService.publishEvent(this.Coluna + "_" + this.Tabela, this.ImagemServidor);
    this.modalController.dismiss();
  }

 

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.files = Array.from(event.target.files);
      this.uploadForm.patchValue({
        files: this.files
      });
    }
  }

  onSubmit() {
    const formData = new FormData();
    let randonNumero = Math.floor(Math.random() * 1000000);
    let nome: string = randonNumero.toString() + this.Tabela + this.Idtabela.toString()
    this.files.forEach(file => formData.append('files', file, nome + '.' + file.name.split('.')[1]));
    if (this.files.length > 1) {
      this.utilService.showAlertCallback("Só é possível enviar um arquivo de cada vez.", null);
      return;
    }


    if (this.temExtensaoValida(this.files) == false) {
      this.utilService.showAlertCallback("São só aceitos arquivos do tipo png, jpg, jpeg, gif, jfif, pdf, txt, doc e mp4", null);
      return;
    }

    this.enviarFotoTabela(formData, nome + '.' + this.files[0].name.split('.')[1])


  }


  temExtensaoValida(files: any[]) {
    let temExtensaoValida = true;
    files.forEach((file) => {
      let extensao: string = file.name.split('.')[1];
      if (extensao.toLowerCase() != 'jpeg'
      && extensao.toLowerCase() != 'mp4' && extensao.toLowerCase() != 'mp3'
      && extensao.toLowerCase() != 'mp4' && extensao.toLowerCase() != 'mp3'
      && extensao.toLowerCase() != 'opus' && extensao.toLowerCase() != 'flac'
      && extensao.toLowerCase() != 'wav' && extensao.toLowerCase() != 'mkv'

      && extensao.toLowerCase() != 'pdf' && extensao.toLowerCase() != 'doc'
      && extensao.toLowerCase() != 'txt' && extensao.toLowerCase() != 'docx'
        && extensao.toLowerCase() != 'gif' && extensao.toLowerCase() != 'png'
        && extensao.toLowerCase() != 'jpeg' && extensao.toLowerCase() != 'jpg'
        && extensao.toLowerCase() != 'jfif') {
        temExtensaoValida = false;
      }

    });
    return temExtensaoValida;
  }


  async enviarFotoTabela(formData: any, nome: string) {
    let loading = await this.loadingController.create({ message: "Verificando..." });
    loading.present();
    this.apiabertaService.enviarImagemTabela(formData).then((r) => {
      this.atualizarImagemGeral(nome);
      loading.dismiss();
    }).catch((r) => {
      this.utilService.showAlertCallback('Imagem NÃO pode ser enviada');
      loading.dismiss();

    }).finally(() => {
      loading.dismiss();
    })
  }

  async atualizarImagemGeral(nome: string) {
    let loading = await this.loadingController.create({ message: "Atualizando..." });
    loading.present();

    let jImagem = {
      Tabela: this.Tabela,
      Idtabela: this.Idtabela,
      Nome: nome,
      Descricao: 'descricao',
      Texto: 'texto',
      Usuario: 0,
      Coluna: this.Coluna

    };

    //this.ImagemServidor="/assets/remove_uti.png";

    setTimeout(() => {
      this.apiabertaService.atualizarImagemGeral(jImagem).then((response: any) => {
        loading.dismiss();

        this.utilService.showAlertCallback('Arquivo enviado com SUCESSO!', () => {
          this.ImagemServidor = this.utilService.obterUrlDaImagemDaApi() + nome;
          this.Imagem = nome; // nome da imagem
        });
      }).catch(() => {
        loading.dismiss();
        this.utilService.showAlertCallback('Ocorreu algum problema. Imagem NÃO enviada', () => {
          this.ImagemServidor = "";

        }).finally(() => {
          this.ImagemServidor = "";
          loading.dismiss();
        })
      })

    }, 500)

  }

  // ROTINAS PARA CÂMERA
  tirarFoto() {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true,
      cameraDirection: this.camera.Direction.FRONT,
      targetWidth: 960,
      targetHeight: 540,
    }

    this.camera.getPicture(options).then((imageData) => {
      this.imageURI = imageData;
      //debugger
      this.enviarArquivo();
    }, (err) => {
      this.utilService.showAlertCallback("Imagem NÃO foi selecionada!");
    });
  }


  getImage() {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      //  sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
      sourceType: this.camera.PictureSourceType.SAVEDPHOTOALBUM
    }
    
    this.camera.getPicture(options).then((imageData) => {
      this.imageURI = imageData;
      setTimeout(()=>{
        this.enviarArquivo();
      },1000);
    }, (err) => {
      this.utilService.showAlertCallback("Imagem NÃO foi selecionada!");
    });
  }

  async enviarArquivo() {
    let mensagem = 'imagem sendo enviada....';
    let loading = await this.loadingController.create({
      message: mensagem,
    });
    loading.present();
    let randonNumero = Math.floor(Math.random() * 1000000);
    let imagem = "U" + this.Idtabela.toString() + randonNumero + ".png";
    const fileTransfer: FileTransferObject = this.transfer.create();

    let options: FileUploadOptions = {
      fileKey: 'ionicfile',
      fileName: imagem,
      chunkedMode: false,
      mimeType: "image/png",
      headers: {}
    }
    let urlUpload = this.utilService.obterUrlDaApi() + "Util/EnviarArquivo";
    fileTransfer.upload(this.imageURI, urlUpload, options)
      .then((data) => {
        //debugger;
        this.Imagem = imagem;
        this.ImagemServidor = this.utilService.obterUrlDaImagemDaApi() + this.Imagem


        loading.dismiss();
        this.gravarSqlServer(imagem)

      }, (err) => {
        console.error(err);
        this.utilService.showAlertCallback("Imagem NÃO pode ser enviada!");
      }).finally(function () {
        loading.dismiss();
      });
  }

  async gravarSqlServer(imagem:any) {

    let jImagem = {
      Idtabela: this.Idtabela,
      Usuario: 0,
      Tabela: this.Tabela,
      Nome: imagem,
      Descricao: 'Descricao',
      Texto: 'Texto',
      Coluna: this.Coluna

    };

    this.apiabertaService.atualizarImagemGeral(jImagem)
    .then((response) => {
     this.utilService.showAlertCallback("Imagem enviado com sucesso!", null);

    })
    .catch((erro) => {
      this.utilService.showAlertCallback(JSON.stringify(erro));
    });
  }
 
  

}
