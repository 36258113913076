import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, LoadingController, AlertController, IonThumbnail } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IonContent } from '@ionic/angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ImagemService } from '../../services/imagem.service';


@Component({
  selector: 'app-mensagem',
  templateUrl: './mensagem.component.html',
  styleUrls: ['./mensagem.component.scss'],
})
export class MensagemComponent implements OnInit {
  @ViewChild('ancoraMensagem', null) ancoraMensagem: ElementRef;
  @ViewChild('ancoraContent', null) ancoraContent: IonContent;

  mensagemForm: any;
  dadosRemove: any;
  Origem: number = 0;
  Destino: number = 0;
  aMensagem: any = [];
  lRet: boolean;
  Mensagem: string = "";
  testeValido: boolean;
  Nome: string = "";
  ImagemDestino: string = ""
  ImagemOrigem: string = ""
  intervalId: any;
  constructor(
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private imagemService: ImagemService

  ) {

    this.mensagemForm = formBuilder.group({
      Mensagem: ['', ''],

    })
    this.Origem = parseInt(this.activatedRoute.snapshot.paramMap.get('Origem'));
    this.Destino = parseInt(this.activatedRoute.snapshot.paramMap.get('Destino'));
    this.Nome = this.activatedRoute.snapshot.paramMap.get('Nome');
    this.ImagemDestino = this.activatedRoute.snapshot.paramMap.get('ImagemDestino');
    this.ImagemOrigem = this.activatedRoute.snapshot.paramMap.get('ImagemOrigem');

  }

  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.selecionarMensagemOrigemDestino();
    }, 60000); // 1 minuto para atualizar
  }

  ngOnDestroy() {
    clearInterval(this.intervalId); // Limpa o intervalo quando o componente for destruído
  }




  ionViewDidEnter() {
    this.limpaDados();
    this.selecionarMensagemOrigemDestino();

    this.utilService.getEvent().subscribe(data => {
      if (data.key == "Imagem_Mensagem") {
        this.selecionarMensagemOrigemDestino();
      }
    });

  }

  ngAfterViewChecked() {
    // Chama a rolagem para o fundo sempre que a visualização for atualizada
    this.scrollToBottom();
  }


  scrollToTop() {
    this.ancoraContent.scrollToTop(300); // Rolagem para o topo com duração de 300ms
  }



  // Função para rolar até o fundo
  scrollToBottom() {
    if (this.ancoraContent) {
      this.ancoraContent.scrollToBottom(300); // Rolagem para o fundo com duração de 300ms
    }
  }




  closeModal() {
    this.utilService.publishEvent("Mensagem", this.Nome);
    this.ngOnDestroy();
    this.modalController.dismiss();
  }



  async testarDado(origem: number) {
    let loading = await this.loadingController.create({ message: "Verificando..." });
    loading.present();
    if (origem == 1) {
      this.Mensagem = this.mensagemForm.value.Mensagem;
      this.lRet = this.utilService.valiidarCampoGenerico("Mensagem", this.Mensagem, () => {
        this.utilService.focusInput(this.ancoraMensagem);
      });
      if (this.lRet == false) {
        loading.dismiss();
        return;
      }
    }

    if (origem == 2 && this.Mensagem == '') {
      this.Mensagem = "Arquivo"
    }

    let aMensagem: any = []

    aMensagem = {
      Origem: this.Origem,
      Destino: this.Destino,
      Mensagem: this.Mensagem,
      Prioridade: 1
    }
    loading.dismiss();

    this.adicionarMensagem(aMensagem, origem)
  }




  async adicionarMensagem(aMensagem: any, origem: number) {
    let loading = await this.loadingController.create({ message: "Gravando..." });
    loading.present();
  
    this.apiService.adicionarMensagem(aMensagem)
      .then((response: any) => {
        loading.dismiss();
        if (response[0].Id > 0) {
          this.selecionarMensagemOrigemDestino()
          if (origem == 2) {
            this.showUploadImagem(origem, response[0].Id)
          }
          this.limpaDados();
        }


      })
      .catch((err) => {
        loading.dismiss()
        this.utilService.showAlertCallback('Dados NÃO poderam ser ADICIONADOS.', null);
      });

  }

  async atualizarMensagem() {
    let loading = await this.loadingController.create({ message: "Atualizando..." });
    loading.present();

    let aMsg: any = [];
    loading.dismiss();
    for (let i = 0; i < this.aMensagem.length; i++) {
      if (this.aMensagem[i].Prioridade == 1 && this.aMensagem[i].Origem != this.Origem) {
        aMsg = { Id: this.aMensagem[i].Id }
        this.apiService.atualizarMensagem(aMsg)
          .then((response: any) => {
            loading.dismiss();
            if (response[0].Retorno == 1) {
              this.aMensagem[i].Prioridade = response[0].Prioridade
              this.aMensagem[i].Datavisualizacao = response[0].Datavisualizacao
              
            }
           
          })
          .catch((response) => {
            loading.dismiss();
            this.utilService.showAlertCallback('Dados NÃO poderam ser ADICIONADOS.', null);
          }).finally(()=>{
            loading.dismiss();
          });
      }
    }



  }



  async onInputChange(campo: string, valor: string, tipo: string) {
    this.utilService.onInputChangeGeral(campo, valor, tipo).then((res: any) => {
      this.mensagemForm.controls[campo].setValue(res.Valor);
      this.testeValido = res.Teste;
    });

  }

  async showUploadImagem(origem: number, Id: number) {
    let tabela = "";
    let coluna = ""
    if (origem == 2) {
      tabela = 'Mensagem';
      coluna = "imagem"
    }
    this.imagemService.showUploadImagem(tabela, coluna, Id, '').then((r) => {
    })
  }



  async limpaDados() {
    this.scrollToBottom();
    this.mensagemForm.controls["Mensagem"].setValue('');
    this.utilService.focusInput(this.ancoraMensagem);


  }

  async selecionarMensagemOrigemDestino() {
    let loading = await this.loadingController.create({ message: "Selecionando mensagens..." });
    loading.present();
  
    this.aMensagem = [];
    this.apiService.selecionarMensagemOrigemDestino(this.Origem, this.Destino, 2)
      .then((response: any) => {
        loading.dismiss();
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            response[i].Link = this.extrairLinks(response[i].Mensagem)
          }

          setTimeout(() => {
            loading.dismiss();
            this.aMensagem = response;
          }, 50);

          setTimeout(() => {
            loading.dismiss()
            this.aMensagem = this.utilService.verificaImagensMensagem(this.aMensagem);
            this.atualizarMensagem();
          }, 50);
        }

      }).catch(() => {
        loading.dismiss()
        this.utilService.showAlertCallback("Não foi possível seecionar as mensagem")
      });
  }

  extrairLinks(mensagem: string): string[] {
    // Expressão regular para identificar links que comecem com http:// ou https://
    const regex = /https?:\/\/[^\s]+/g;
    const links = mensagem.match(regex);

    // Retorna os links encontrados ou um array vazio se nenhum link for encontrado
    return links ? links : [];
  }

  async info(Topico: string, Texto: string, Imagem: string) {
    this.utilService.showModalInfo(Topico, Texto, Imagem);
  }
}








