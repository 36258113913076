import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, LoadingController, AlertController, IonThumbnail } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IonSelect } from '@ionic/angular';
import { ApiabertaService } from '../../services/apiaberta.service';

@Component({
  selector: 'app-estoque-saida',
  templateUrl: './estoque-saida.component.html',
  styleUrls: ['./estoque-saida.component.scss'],
})
export class EstoqueSaidaComponent implements OnInit {
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraQuantidade', null) ancoraQuantidade: ElementRef;
  @ViewChild('ancoraData', { static: false }) ancoraData: ElementRef;
  @ViewChild('ancoraVeiculo', null) ancoraVeiculo: IonSelect;
  @ViewChild('ancoraEvento', null) ancoraEvento: IonSelect;
  @ViewChild('ancoraObservacao', null) ancoraObservacao: ElementRef;

  Botao: string = "ADICIONAR"
  estoquesaidaForm: FormGroup;
  Nome: string = "";
  Estoque: number = 0;
  Minimo: number = 0;
  Atual: number = 0;
  Maximo: number = 0;
  IdEstoqueSaida: number = 0;
  lRet: boolean;
  aEstoquesaida: any = [];
  Usuario: number = 0;
  aVeiculo: any = [];
  aEvento: any = [];
  Opcao: any = '1';
  constructor(private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private apiabertaService: ApiabertaService

  ) {


    this.estoquesaidaForm = formBuilder.group({
      Id: ['', ''],
      Quantidade: ['', ''],
      Data: ['', ''],
      Veiculo: ['', ''],
      Evento: ['', ''],
      Observacao: ['', ''],
      Opcao: ['', ''],
    })

    this.Nome = this.activatedRoute.snapshot.paramMap.get('Nome');
    this.Estoque = parseInt(this.activatedRoute.snapshot.paramMap.get('Estoque'));
    this.Minimo = parseInt(this.activatedRoute.snapshot.paramMap.get('Minimo'));
    this.Atual = parseInt(this.activatedRoute.snapshot.paramMap.get('Atual'));
    this.Maximo = parseInt(this.activatedRoute.snapshot.paramMap.get('Maximo'));
    this.IdEstoqueSaida = parseInt(this.activatedRoute.snapshot.paramMap.get('IdEstoqueSaida'));
    this.Usuario = parseInt(this.activatedRoute.snapshot.paramMap.get('Usuario'));
  }

  ngOnInit() {

    this.apiService.selecionarVeiculoNome('0').then((response: any) => {
      this.aVeiculo = response;
    })


    this.apiabertaService.selecionarEvento('_rm_Evento', 'Id-Nome-Ativo').then((response: any) => {
      this.aEvento = response;
    })
  }

  ionViewDidEnter() {

    setTimeout(() => {
      if (this.IdEstoqueSaida > 0) {
        this.SelecionarDadosId(this.IdEstoqueSaida);
      } else {
        this.limpaDados();
      }

    }, 1000)

  }

  async SelecionarDadosEstoqueId() {
    this.apiService.selecionarEstoqueId(this.Estoque)
    .then((response: any) => {
        this.Minimo = response[0].Minimo;
        this.Maximo = response[0].Maximo;
        this.Atual = response[0].Atual;
        console.log("Atual:" + this.Atual)
        this.utilService.publishEvent("Estoque", this.Estoque);

      
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Dados NÃO encontrados.', null);
    });

  }


  async limpaDados() {
    this.estoquesaidaForm.controls["Id"].setValue('');
    this.estoquesaidaForm.controls["Veiculo"].setValue('');
    this.estoquesaidaForm.controls["Evento"].setValue('');
    this.estoquesaidaForm.controls["Quantidade"].setValue('');
    this.estoquesaidaForm.controls["Observacao"].setValue('');
    this.estoquesaidaForm.controls["Opcao"].setValue(this.Opcao);

    this.Botao = "ADICIONAR";
    let Data: string = this.utilService.solucaoGeralData(new Date(), 1);
    this.estoquesaidaForm.controls["Data"].setValue(Data);
    this.utilService.focusInput(this.ancoraQuantidade);


  }

  async selecionarOpcao() {
    this.Opcao = this.estoquesaidaForm.value.Opcao;
    if (this.Opcao == 1) {
      this.estoquesaidaForm.controls["Evento"].setValue('');
    } else {
      this.estoquesaidaForm.controls["Veiculo"].setValue('');

    }
  }



  async SelecionarDadosId(id: number) {
    this.apiService.selecionarEstoqueSaidaId(id)
      .then((response: any) => {
        if (response.length > 0) {
          this.getDados(response);

        }

      })
      .catch((response) => {
        this.utilService.showAlertCallback('Dados NÃO encontrados.', null);
      });

  }

  async getDados(response: any) {

    let Data = this.utilService.solucaoGeralData(response[0].Data, 1);

    setTimeout(()=>{
      this.estoquesaidaForm.controls["Data"].setValue(Data);

    })



    this.estoquesaidaForm.controls["Id"].setValue(response[0].Id);
    this.estoquesaidaForm.controls["Quantidade"].setValue(response[0].Quantidade);
    this.estoquesaidaForm.controls["Veiculo"].setValue(response[0].Veiculo.toString());
    this.estoquesaidaForm.controls["Evento"].setValue(response[0].Evento);
    this.estoquesaidaForm.controls["Observacao"].setValue(response[0].Observacao);

    if (parseInt(response[0].Veiculo) > 0) {
      this.Opcao = 1;
      this.estoquesaidaForm.controls["Opcao"].setValue('1');
    }else{
      this.Opcao = 2;
      this.estoquesaidaForm.controls["Opcao"].setValue('2');

    }

    this.Botao = "ATUALIZAR";

  }

  closeModal() {
    this.modalController.dismiss();
  }

  async testarDado() {
    let loading = await this.loadingController.create({ message: "Verificando os dados..." });
    loading.present();
    this.lRet = this.utilService.valiidarCampoGenerico("Quantidade", this.estoquesaidaForm.value.Quantidade, () => {
      this.utilService.focusInput(this.ancoraQuantidade);
    });
    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.valiidarCampoGenerico("Data", this.estoquesaidaForm.value.Data, () => {
      this.utilService.focusInput(this.ancoraData);
    });
    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    if (this.Opcao == 1) {
      this.lRet = this.utilService.valiidarCampoGenerico("Veiculo", this.estoquesaidaForm.value.Veiculo, () => {
        this.utilService.focusInput(this.ancoraVeiculo);
      });

      if (this.lRet == false) {
        loading.dismiss();
        return;
      }

    }

    if (this.Opcao == 2) {

      this.lRet = this.utilService.valiidarCampoGenerico("Evento", this.estoquesaidaForm.value.Evento, () => {
        this.utilService.focusInput(this.ancoraEvento);
      });

      if (this.lRet == false) {
        loading.dismiss();
        return;
      }

    }



    this.aEstoquesaida = []
    loading.dismiss();

    let Veiculo:any = this.estoquesaidaForm.value.Veiculo;
    if (Veiculo == '') {
      Veiculo = 0
    }

    let Evento:any = this.estoquesaidaForm.value.Evento;
    if (Evento == '') {
      Evento = 0
    }


    setTimeout(() => {
      this.aEstoquesaida = {
        Id: this.estoquesaidaForm.value.Id,
        Quantidade: this.estoquesaidaForm.value.Quantidade,
        Data: this.estoquesaidaForm.value.Data,
        Evento: parseInt(Evento),
        Veiculo: parseInt(Veiculo),
        Observacao: this.estoquesaidaForm.value.Observacao,
        Usuario: this.Usuario,
        Estoque: this.Estoque,


      }

  


      this.presentAlertConfirm();
    }, 1000); //1 seg



  }

  async presentAlertConfirm() {
    let sheader = 'ATUALIZAÇÃO';
    if (this.estoquesaidaForm.value.Id == '' || this.estoquesaidaForm.value.Id == null || this.estoquesaidaForm.value.Id == undefined) {
      sheader = 'ADIÇÃO';
    }
    const alert = await this.alertController.create({
      header: sheader,
      message: '<strong>CONFIRMA a gravação desses dados?</strong>',
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.gravarDados();

          }
        }
      ]
    });

    await alert.present();
  }

  async gravarDados() {

    if (this.estoquesaidaForm.value.Id == '' || this.estoquesaidaForm.value.Id == null || this.estoquesaidaForm.value.Id == undefined) {
      this.AdicionarDados();
    } else {
      this.AtualizarDados();
    }

  }

  async AdicionarDados() {
    this.apiService.adicionarEstoqueSaida(this.aEstoquesaida)
      .then((response: any) => {
        if (response[0].Retorno == 1) {
          this.utilService.showAlertCallback('Dados incluídos com sucesso!', null)
          this.estoquesaidaForm.controls["Id"].setValue(response[0].Id);
          this.Botao = "ATUALIZAR"
          this.SelecionarDadosEstoqueId();
        } else {
          this.utilService.showAlertCallback('Esse PRODUTO DE ESTOQUE já está cadastrado!', null)

        }


      })
      .catch((response) => {
        this.utilService.showAlertCallback('Dados NÃO poderam ser incluídos.', null);
      });

  }

  async AtualizarDados() {
    this.apiService.atualizarEstoqueSaida(this.aEstoquesaida)
      .then((response: any) => {
        if (response[0].Retorno == 1) {
          this.SelecionarDadosEstoqueId();
          this.utilService.showAlertCallback('Dados atualizados com sucesso!', null);
        }

      })
      .catch((response) => {
        this.utilService.showAlertCallback('Dados NÃO poderam ser atualizados.', null);
      });

  }

}
