import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { PopoverController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  aMenu:any=[];
  dadosRemove:any;
  NomeUsuario:string="";
  Data:string="";
  Administrador:any=0;
  Coordenador:any=0;
  sAdministrador:string="N";
  sCoordenador:string="N";
  Imagem:string='';
  constructor(
    private utilService:UtilService,
    private popoverController:PopoverController,
    private alertController:AlertController

  ) { }

  ngOnInit() {

    this.dadosRemove = this.utilService.obterDadosDoSistema();
    this.NomeUsuario = this.dadosRemove.Usuario[0].NomeUsuario.split(' ')[0].toUpperCase();
    this.Data = this.utilService.getDataBrowserBrasil(Date());
    this.Administrador =this.dadosRemove.Usuario[0].Administrador;
    this.Coordenador =this.dadosRemove.Usuario[0].Coordenador;
    if(this.Administrador == '1') {
      this.sAdministrador = 'Sim'
    }

    if(this.Coordenador == '1') {
      this.sCoordenador = 'Sim'
    }
    let Imagem:string = this.dadosRemove.Usuario[0].Imagem;
    if (Imagem != '' && Imagem != null && Imagem != undefined) {
      this.Imagem = this.utilService.obterUrlDaImagemDaApi() + Imagem
      this.utilService.verificarArquivo(this.Imagem, (achou) => {
        // Se a imagem NÃO foi encontrada, substitui pela imagem padrão
        if (achou === false) {
          this.Imagem = "/assets/removevoce.png";
        }
      });
    }else{
       this.Imagem="/assets/removevoce.webp"
    }

    this.aMenu= [
      {Nome: 'Usuários', Link: 'usuario-pesquisa', Cor: '', Icone: 'people-sharp', Administrador: '1'},
      {Nome: 'Pacientes', Link: 'paciente-pesquisa', Cor: 'light',  Icone: 'person-add-outline', Administrador: '1'},
      {Nome: 'Empresas', Link: 'empresa-pesquisa', Cor: '' , Icone: 'code-working-sharp', Administrador: '1'},
      {Nome: 'Produtos', Link: 'produto-pesquisa' , Cor: 'light' , Icone: 'attach-sharp', Administrador: '1'},
      {Nome: 'Simulação de contratos', Link: 'produto-simulacao' , Cor: '',  Icone: 'calculator-sharp', Administrador: '1',  },
      {Nome: 'Contratos', Link: 'contrato-pesquisa', Cor: 'light',  Icone: 'document-lock-sharp', Administrador: '1'},
      {Nome: 'Veículos', Link: 'veiculo-pesquisa', Cor: '',  Icone: 'car-sport-outline', Administrador: '1'},
      {Nome: 'Remoção', Link: 'remocao-pesquisa' , Cor: 'light',  Icone: 'receipt-sharp', Administrador: '1'},
      {Nome: 'Estoque', Link: 'estoque-pesquisa' , Cor: '',  Icone: 'document-attach-sharp', Administrador: '1'},
      {Nome: 'Mensagem', Link: 'administrativo', Cor: 'light', Icone: 'send-sharp', Administrador: '0'},

    ]

  }


  async movimentar(pagina: string) {
    this.popoverController.dismiss();
    this.utilService.Movimentar(pagina);
  }

  async logoff() {
    const alert = await this.alertController.create({
      header: 'ATENÇÃO!',
      message: 'Tem certeza que deseja sair do sistema?',
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: 'Sim, quero sair',
          handler: () => {
            localStorage.removeItem('dadosRemove'); 
            localStorage.removeItem('token');
            this.movimentar('tabs/tab1');
          }
        }
      ]
    });

    alert.present();

  }

}
